import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import CardWithProgressLoading from '../CardWithLoadingProgress';
import Select from '../Select';
import Input from '../Input';
import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';
import { useLazyRequest } from '../../hooks';
import { createAgent } from '../../../services/merchantsService';

import { getHelperText, hasError } from '../../../utils';
import { USER_ROLES_OPTIONS } from '../../../constants/user';

import { FIELDS_NAME, initialValues, validationSchema } from './utils';
import styles from './styles';

const useStyles = makeStyles(styles);

const InviteUserForm = ({ onSucess }) => {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [, loadingInviteUser, , requestInviteUser] = useLazyRequest({
    request: createAgent,
    withPostSuccess: () => {
      enqueueSnackbar(t('users.invite.successMessage'), {
        variant: 'success',
      });

      onSucess();
    },
    withPostFailure: (error) => {
      let messageError = t('users.invite.errorMessage');

      if (error.data.error.code === 400) {
        messageError = t('users.invite.alreadyExists');
      }

      enqueueSnackbar(messageError, { variant: 'error' });
    },
  });

  const executeInviteUser = useCallback(
    async (values, { setSubmitting, resetForm }) => {
      await requestInviteUser(values);

      setSubmitting(false);
      resetForm();
    },
    [requestInviteUser],
  );

  const {
    touched,
    errors,
    submitForm,
    getFieldProps,
    dirty,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema: validationSchema(t),
    onSubmit: executeInviteUser,
  });

  return (
    <CardWithProgressLoading
      loading={false}
      className={classes.card}
      fullHeight={false}
    >
      <Input
        label={t('users.email')}
        error={hasError(touched, errors, FIELDS_NAME.EMAIL)}
        helperText={getHelperText(touched, errors, FIELDS_NAME.EMAIL)}
        autoComplete="off"
        {...getFieldProps(FIELDS_NAME.EMAIL)}
        className={classes.input}
      />

      <Select
        label={t('users.role')}
        error={hasError(touched, errors, FIELDS_NAME.ROLE)}
        helperText={getHelperText(touched, errors, FIELDS_NAME.ROLE)}
        autoComplete="off"
        {...getFieldProps(FIELDS_NAME.ROLE)}
        data={USER_ROLES_OPTIONS}
        className={classes.select}
      />

      <ButtonWithCirculaProgress
        variant="contained"
        color="secondary"
        disabled={!dirty || !isValid || loadingInviteUser}
        loading={loadingInviteUser}
        label={t('users.invite.title')}
        handleButtonClick={submitForm}
        className={classes.button}
      />
    </CardWithProgressLoading>
  );
};

InviteUserForm.propTypes = {
  onSucess: PropTypes.func.isRequired,
};

export default InviteUserForm;
