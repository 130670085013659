import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Dropdown from '../Dropdown';

const HeaderDropdown = ({ label, data, menuMinWidth }) => {
  const { t } = useTranslation('common');

  const labelTranlated = useMemo(() => t(`headerDropdown.${label}.title`), [
    label,
    t,
  ]);

  const dataTranslated = useMemo(
    () =>
      data.map(({ title, path, collapseData }) => {
        let collapseProps;

        if (collapseData) {
          collapseProps = collapseData.map(
            ({ title: titleCollpase, path: pathCollpase }) => ({
              title: t(
                `headerDropdown.${label}.data.${title}.collapseData.${titleCollpase}.title`,
              ),
              path: pathCollpase,
            }),
          );
        }

        return {
          title: t(`headerDropdown.${label}.data.${title}.title`),
          path,
          collapseData: collapseProps,
        };
      }),
    [data, label, t],
  );

  return (
    <Dropdown
      label={labelTranlated}
      data={dataTranslated}
      menuMinWidth={menuMinWidth}
    />
  );
};

HeaderDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
      collapseData: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
        }).isRequired,
      ),
    }).isRequired,
  ).isRequired,
  menuMinWidth: PropTypes.number,
};

HeaderDropdown.defaultProps = {
  menuMinWidth: undefined,
};

export default HeaderDropdown;
