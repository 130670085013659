const sizeSelectedCountry = 30;

export default (theme) => ({
  root: {
    position: 'relative',
  },
  rootBtn: {
    alignItems: 'flex-end',
    backgroundColor: 'transparent',
    border: 0,
    color: '#6E49AE',
    cursor: 'pointer',
    display: 'flex',
    fontSize: 14,
    justifyContent: 'center',
    padding: 0,
    position: 'relative',
    textAlign: 'left',
    zIndex: 1,

    '&:focus, &:focus-visible': {
      outline: 'none',
    },

    '& img': {
      height: sizeSelectedCountry,
      width: sizeSelectedCountry,
    },
  },
  menu: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    boxShadow: '0px 6px 10px 2px #00000026, 0px 2px 3px 0px #0000004D',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    justifyContent: 'center',
    left: 0,
    opacity: 1,
    position: 'absolute',
    top: `calc(${sizeSelectedCountry}px + 13px)`,
    width: 117,
    zIndex: 1901,
  },
  menuLeftMobile: {
    [theme.breakpoints.down(536)]: {
      left: 'auto',
      right: 0,
    },
  },
  menuItems: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  menuElementContent: {
    display: 'flex',
    width: '100%',

    '&:not(:last-child)': {
      borderBottom: '1px solid #CAC4D0',
    },
  },
  menuElementBtn: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 0,
    color: theme.palette.common.black,
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    gap: 10,
    justifyContent: 'flex-start',
    padding: '14px 10px',
    position: 'relative',
    textAlign: 'left',

    '& img': {
      height: 25,
      width: 25,
    },
  },
  menuElementText: {
    border: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: 15,
    fontWeight: 400,
  },
});
