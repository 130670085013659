export const USER_ROLES_OPTIONS = [
  {
    labelTranslatedKey: 'users.rolesData.agentOfPayment',
    value: 'agent_of_payment',
  },
];

export const USER_STATUS_OPTIONS = [
  { labelTranslatedKey: 'users.statusData.invited', value: 'invited' },
  { labelTranslatedKey: 'users.statusData.connected', value: 'connected' },
  { labelTranslatedKey: 'users.statusData.active', value: 'active' },
  { labelTranslatedKey: 'users.statusData.freeze', value: 'freeze' },
];
