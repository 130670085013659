import { guttersApp, appHeightHeader } from '../../../utils/stylesUtils';

export default (theme) => ({
  root: {
    background: theme.palette.background.default,
    boxShadow: 'none',
    justifyContent: 'flex-end',
    borderBottom: `1px solid ${theme.palette.common.white}`,
    paddingBottom: 14,
    paddingTop: 14,
    height: appHeightHeader.desktop,

    [theme.breakpoints.down(768)]: {
      justifyContent: 'center',
      height: appHeightHeader.mobile,
      paddingBottom: 12,
      paddingTop: 12,
    },
  },
  toolbar: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 auto',
    width: '100%',
    minHeight: 'auto !important',

    ...guttersApp(theme.spacing, theme.breakpoints),
  },
  mainLogo: {
    display: 'flex',

    '& img': {
      height: 46,
      width: 42,
    },

    [theme.breakpoints.down(768)]: {
      '& img': {
        height: 39,
        width: 36,
      },
    },
  },
  navBar: {
    alignItems: 'center',
    display: 'flex',
    gap: 50,

    [theme.breakpoints.down(769)]: {
      gap: 0,
      display: 'none',
    },
  },
  actions: {
    alignItems: 'center',
    display: 'flex',
    gap: 30,
  },
  authContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: 10,

    [theme.breakpoints.down(769)]: {
      display: 'none',
    },
  },
  authBtn: {
    margin: 0,
    minWidth: 137,

    [theme.breakpoints.down(768)]: {
      minWidth: 115,
    },
  },
  authBtnOutlined: {
    backgroundColor: 'transparent',
    border: '1px solid #8B54F7',
    borderRadius: 16,
    color: '#8B54F7',
    cursor: 'pointer',
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 'bold',
    padding: 12,
    width: '100%',

    '&:hover': {
      backgroundColor: 'rgba(166,125,248, .5)',
    },
  },
  authBtnSecondary: {
    backgroundColor: '#8B54F7',
    border: '1px solid #8B54F7',
    borderRadius: 16,
    color: theme.palette.common.white,
    cursor: 'pointer',
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 'bold',
    padding: 12,
    width: '100%',

    '&:hover': {
      backgroundColor: 'rgba(166,125,248, .5)',
    },
  },
  authBtnIcon: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: 10,

    '& img': {
      height: 19,
      marginRight: 6,
      width: 19,
    },
  },
});
