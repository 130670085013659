export default (theme) => ({
  card: {
    boxShadow: 'none',

    '& .MuiCardContent-root': {
      minHeight: 'auto',
      padding: 16,
      gap: 10,

      '&:last-child': {
        paddingBottom: 16,
      },

      [theme.breakpoints.up(768)]: {
        flexDirection: 'row',
        gap: 20,
      },
    },

    '& .MuiInputLabel-outlined': {
      color: '#8B54F7',
    },

    '& .MuiSelect-icon': {
      fontSize: 40,
      top: 'calc(50% - 20px)',
      color: '#8B54F7',

      '&.MuiSelect-iconOpen': {
        transform: 'rotate(90deg)',
      },
    },

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#8B54F7',
      },
    },

    '& .MuiButton-root': {
      padding: 14,
      borderRadius: 16,
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 700,
      textTransform: 'none',
    },
  },
  select: {
    width: '100%',

    [theme.breakpoints.up(768)]: {
      maxWidth: 250,
    },
  },
  button: {
    width: '100%',

    '& .MuiButton-root': {
      width: '100%',
    },

    [theme.breakpoints.up(768)]: {
      maxWidth: 200,
    },
  },
});
