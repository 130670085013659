import axios from 'axios';

import API from '../config/api';

import { LIQUIDATION_TYPES } from '../utils/movements';

const ENDPOINTS = {
  GET_OPERATIONS: '/internal/v1/merchants/operations',
  INTEGRATIONS: '/internal/v1/merchants/integrations',
  ENABLED_TOKEN: '/internal/v1/merchants/integrations/request_to_enable',
  START_ASSOCIATION: '/internal/v1/users/authenticator/start_association',
  FINISH_ASSOCIATION: '/internal/v1/users/authenticator/finish_association',
  GET_PAYMENTS: '/internal/v1/merchants/payments',
  GET_SUMMARY_BY_TOKENS: '/internal/v1/merchants/movements/summary_by_tokens',
  GET_MOVEMENTS: '/internal/v1/merchants/movements',
  GET_PAYMENTS_METHODS: '/internal/v1/merchants/retirements/payment_methods',
  CREATE_LIQUIDATION: '/internal/v1/merchants/retirements',
  CALCULATE_AMOUNT_LIQUIDATION:
    '/internal/v1/merchants/retirements/calculate_amount',
  SAVE_FISCAL_INFO: '/internal/v1/merchants/fiscal_informations',
  DOMICILIAL_INFO: '/internal/v1/merchants/domiciles',
  BANK_INFO: '/internal/v1/merchants/banks',
  WALLETS_AVAILABLES: '/internal/v1/merchants/wallets/available',
  WALLETS: '/internal/v1/merchants/wallets',
  CHANGE_PASSWORD: '/internal/v1/merchants/change_password',
  CONFIRM_OPERATION: '/internal/v1/operations/confirm',
  GET_SETTINGS: '/internal/v1/merchants/settings',
  SETTINGS: '/internal/v1/merchants/settings',
  QR_URL: '/internal/v1/merchants/integrations/payment_url',
  PAYMENTS_PEWVIEW:
    '/internal/v1/merchants/integrations/payments/request_preview',
  GET_RETIREMENTS: '/internal/v1/merchants/retirements',
  SEND_EMAIL_ADMINS: '/internal/v1/merchants/integrations/request_to_print',
  GET_ACTIVE_AUTOSWAP: '/internal/v1/merchants/auto_swap',
  CHANGE_ACTIVE_AUTOSWAP: '/internal/v1/merchants/auto_swap',
  GET_REPORTS: '/internal/v1/merchants/reports',
  CREATE_REPORT: '/internal/v1/merchants/reports',
  GET_LINK_DOWNLOAD_REPORT: 'internal/v1/merchants/reports/:report_id',
  GET_CURRENCIES_NETWORKS_CONFIG: '/internal/v1/merchants/config_currencies',
  UPDATE_ALLOW_CURRENCIES_NETWORKS_CONFIG:
    '/internal/v1/merchants/config_currencies',
  GET_STORES: '/internal/v1/merchants/stores',
  CREATE_STORE: '/internal/v1/merchants/stores',
  UPDATE_STORE: '/internal/v1/merchants/stores/:store_id',
  GENERATE_PAYMENT: '/internal/v1/merchants/pre_payment',
  DELETE_PREV_PAYMENT: '/internal/v1/merchants/pre_payment/cancel_previous',
  GET_AGENTS: '/internal/v1/merchants/agents',
  CREATE_AGENT: '/internal/v1/merchants/agents',
  DELETE_AGENT: 'internal/v1/merchants/agents/:agent_id',
};

export const getOperations = (queryParams) => {
  const {
    pageSize,
    pageNumber,
    sortField,
    sortOrder,
    type,
    // clientId,
    status,
  } = queryParams;

  return API.get(ENDPOINTS.GET_OPERATIONS, {
    params: {
      per_page: pageSize,
      page: pageNumber,
      column: sortField,
      order_column: sortOrder,
      type,
      // client_id: clientId,
      state: status,
    },
  });
};

export const getIntegrations = () => API.get(ENDPOINTS.INTEGRATIONS);

export const updateIntegrations = ({ webHookUrl }) =>
  API.patch(ENDPOINTS.INTEGRATIONS, { web_hook_url: webHookUrl });

export const enabledToken = () => API.post(ENDPOINTS.ENABLED_TOKEN);

export const startAssociation = () => API.post(ENDPOINTS.START_ASSOCIATION);

export const finishAssociation = ({ securityCode }) =>
  API.post(ENDPOINTS.FINISH_ASSOCIATION, { token: securityCode });

export const getPayments = (queryParams) => {
  const { pageSize, pageNumber, sortField, sortOrder, state } = queryParams;

  return API.get(ENDPOINTS.GET_PAYMENTS, {
    params: {
      per_page: pageSize,
      page: pageNumber,
      column: sortField,
      order_column: sortOrder,
      state,
    },
  });
};

export const getSummaryByTokens = () =>
  API.get(ENDPOINTS.GET_SUMMARY_BY_TOKENS);

export const getMovements = (queryParams) => {
  const {
    pageSize,
    pageNumber,
    currencies,
    type,
    focusedForRetirement,
  } = queryParams;

  return API.get(ENDPOINTS.GET_MOVEMENTS, {
    params: {
      per_page: pageSize,
      page: pageNumber,
      currencies,
      type,
      focused_for_retirement: focusedForRetirement,
    },
  });
};

export const getPaymentsMethods = ({
  liquidationType = LIQUIDATION_TYPES.NORMAL,
  currencyId,
}) =>
  API.get(ENDPOINTS.GET_PAYMENTS_METHODS, {
    params: {
      liquidation_type: liquidationType,
      currency_id: currencyId,
    },
  });

export const createLiquidation = ({
  movementIds,
  currencyId,
  paymentMethod,
  paymentMethodId,
  googleCode,
}) =>
  API.post(
    ENDPOINTS.CREATE_LIQUIDATION,
    {
      movement_ids: movementIds,
      currency_id: currencyId,
      payment_method: paymentMethod,
      payment_method_id: paymentMethodId,
    },
    { headers: { 'code-2fa': googleCode } },
  );

export const calculateAmountLiquidation = ({
  movementIds,
  currencyId,
  paymentMethod,
  paymentMethodId,
}) =>
  API.post(ENDPOINTS.CALCULATE_AMOUNT_LIQUIDATION, {
    movement_ids: movementIds,
    currency_id: currencyId,
    payment_method: paymentMethod,
    payment_method_id: paymentMethodId,
  });

export const saveFiscalInfo = (Params) => {
  const {
    commercialName,
    legalName,
    description,
    ivaCondition,
    businessDocument,
    sourceOfFounds,
    service,
    documentType,
    documentNumber,
  } = Params;

  const bodyFormData = new FormData();

  bodyFormData.append('business_document', businessDocument);
  bodyFormData.append('source_of_founds', sourceOfFounds);
  bodyFormData.append('service', service);
  bodyFormData.append('description', description);
  bodyFormData.append('commercial_name', commercialName);
  bodyFormData.append('legal_name', legalName);
  bodyFormData.append('document_type', documentType);
  bodyFormData.append('document_number', documentNumber);
  bodyFormData.append('iva_condition', ivaCondition);

  return API.post(ENDPOINTS.SAVE_FISCAL_INFO, bodyFormData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const saveDomicilialInfo = (params) => {
  const {
    city,
    avenueStreet,
    streetNumber,
    phoneNumber,
    zipCode,
    floor,
  } = params;

  return API.post(ENDPOINTS.DOMICILIAL_INFO, {
    postal_code: zipCode,
    street_address: avenueStreet,
    street_number: streetNumber,
    telephone_number: phoneNumber,
    city,
    floor,
  });
};

export const getDomicilialInfo = () => API.get(ENDPOINTS.DOMICILIAL_INFO);

export const getBankInfo = () => API.get(ENDPOINTS.BANK_INFO);

export const saveBankInfo = (data) => API.post(ENDPOINTS.BANK_INFO, data);

export const getWalletsAvailables = () => API.get(ENDPOINTS.WALLETS_AVAILABLES);

export const getUserWallets = () =>
  API.get(ENDPOINTS.WALLETS, { params: { focused_to_withdrawals: true } });

export const saveWallet = (params) => {
  const { address, currency } = params;
  const newParams = currency.split('-');

  return API.post(ENDPOINTS.WALLETS, {
    address,
    currency: newParams[0],
    crypto_network: newParams[1],
  });
};

export const changePassword = ({ currentPassword, newPassword }) =>
  API.patch(ENDPOINTS.CHANGE_PASSWORD, {
    current_password: currentPassword,
    new_password: newPassword,
  });

export const ConfirmOperation = (token) =>
  API.post(ENDPOINTS.CONFIRM_OPERATION, { token });

export const saveSettings = (params) =>
  API.post(ENDPOINTS.SETTINGS, params, {
    'Content-Type': 'multipart/form-data',
  });

export const getSettingsInfo = () => API.get(ENDPOINTS.SETTINGS);

export const getQRURL = () => API.get(ENDPOINTS.QR_URL);

export const previewReq = () => API.post(ENDPOINTS.PAYMENTS_PEWVIEW);

export const getRetirements = (queryParams) => {
  const {
    pageSize,
    pageNumber,
    sortField,
    sortOrder,
    paymentMethodType,
  } = queryParams;

  return API.get(ENDPOINTS.GET_RETIREMENTS, {
    params: {
      per_page: pageSize,
      page: pageNumber,
      column: sortField,
      order_column: sortOrder,
      payment_method_type: paymentMethodType,
    },
  });
};

export const sendEmailAdmins = () => API.post(ENDPOINTS.SEND_EMAIL_ADMINS);

export const getCurrenciesAutoSwap = () =>
  API.get(ENDPOINTS.GET_ACTIVE_AUTOSWAP);

export const changeCurrencySelecteAutoSwap = ({ currency }) =>
  API.post(ENDPOINTS.CHANGE_ACTIVE_AUTOSWAP, { currency });

export const getReports = (queryParams) => {
  const { pageSize, pageNumber } = queryParams;

  return API.get(ENDPOINTS.GET_REPORTS, {
    params: {
      per_page: pageSize,
      page: pageNumber,
    },
  });
};

export const createReport = ({ type, options }) =>
  API.post(ENDPOINTS.CREATE_REPORT, {
    type,
    options,
  });

export const getLinkDownloadReport = (reportId) =>
  API.get(ENDPOINTS.GET_LINK_DOWNLOAD_REPORT.replace(':report_id', reportId));

export const getBlobByURLFile = (url, contetType = 'text/csv') =>
  axios.get(url, {
    responseType: 'blob',
    headers: {
      'Content-Type': contetType,
    },
  });

export const getCurrenciesNetworksConfig = () =>
  API.get(ENDPOINTS.GET_CURRENCIES_NETWORKS_CONFIG);

export const updateAllowCurrenciesNetworksConfig = ({ type, id, allow }) =>
  API.post(ENDPOINTS.UPDATE_ALLOW_CURRENCIES_NETWORKS_CONFIG, {
    type,
    id,
    allow,
  });

export const getStores = () => API.get(ENDPOINTS.GET_STORES);

export const createStore = (body) => API.post(ENDPOINTS.CREATE_STORE, body);

export const updateStore = ({ storeId, body }) =>
  API.patch(ENDPOINTS.UPDATE_STORE.replace(':store_id', storeId), body);

export const generatePayment = (body) =>
  API.post(ENDPOINTS.GENERATE_PAYMENT, body);

export const deletePrevPayment = () => API.post(ENDPOINTS.DELETE_PREV_PAYMENT);

export const getAgents = (queryParams) => {
  const { pageSize, pageNumber, sortField, sortOrder, state } = queryParams;

  return API.get(ENDPOINTS.GET_AGENTS, {
    params: {
      per_page: pageSize,
      page: pageNumber,
      column: sortField,
      order_column: sortOrder,
      state,
    },
  });
};

export const createAgent = (body) => API.post(ENDPOINTS.CREATE_AGENT, body);

export const deleteAgent = (agentId) =>
  API.delete(ENDPOINTS.DELETE_AGENT.replace(':agent_id', agentId));
