import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import Layout from '../Layout';
import Section from '../Section';

import styles from './styles';

const useStyles = makeStyles(styles);

const LayoutLogged = ({ children }) => {
  const classes = useStyles();

  return (
    <Layout>
      <Section className={classes.layoutLoggedContentConntainer}>
        {children}
      </Section>
    </Layout>
  );
};

LayoutLogged.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutLogged;
