import React from 'react';
import { makeStyles } from '@material-ui/core';

import { toAbsoluteUrl } from '../../../utils';

import styles from './styles';

const useStyles = makeStyles(styles);

const SocialNetworks = () => {
  const classes = useStyles();

  return (
    <div className={classes.socialNetworks}>
      <a
        target="_blank"
        href="https://www.instagram.com/kriptonmarket/"
        rel="noopener noreferrer"
        className={classes.rrssLink}
      >
        <img
          src={toAbsoluteUrl('/assets/icons/rrss/ic_instagram_purple.png')}
          alt="Instagram Logo"
        />
      </a>

      <a
        target="_blank"
        href="https://www.facebook.com/KriptonMarket"
        rel="noopener noreferrer"
        className={classes.rrssLink}
      >
        <img
          src={toAbsoluteUrl('/assets/icons/rrss/ic_facebook_purple.png')}
          alt="Facebook Logo"
        />
      </a>

      <a
        target="_blank"
        href="https://twitter.com/Kriptonmarket"
        rel="noopener noreferrer"
        className={classes.rrssLink}
      >
        <img
          src={toAbsoluteUrl('/assets/icons/rrss/ic_twitter_purple.png')}
          alt="Twitter Logo"
        />
      </a>

      <a
        href="https://www.youtube.com/channel/UCJuoXX2mlEs2ApdF1OM-JsQ"
        rel="noopener noreferrer"
        className={classes.rrssLink}
      >
        <img
          src={toAbsoluteUrl('/assets/icons/rrss/ic_youtube_purple.png')}
          alt="YouTube Logo"
        />
      </a>

      <a
        target="_blank"
        href="https://www.linkedin.com/company/kripton-mkt"
        rel="noopener noreferrer"
        className={classes.rrssLink}
      >
        <img
          src={toAbsoluteUrl('/assets/icons/rrss/ic_linkedin_purple.png')}
          alt="LinkedIn Logo"
        />
      </a>
      <a
        target="_blank"
        href="mailto:info@kriptonmarket.com"
        rel="noopener noreferrer"
        className={classes.rrssLink}
      >
        <img
          src={toAbsoluteUrl('/assets/icons/rrss/email.svg')}
          alt="Email Logo"
        />
      </a>

      <a
        href="http://qr.afip.gob.ar/?qr=sA8U0zUxa70OsmYHj0ihYQ,,"
        target="_F960AFIPInfo"
        className={classes.afipLink}
      >
        <img
          src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg"
          alt="Afip img"
        />
      </a>
    </div>
  );
};

export default SocialNetworks;
