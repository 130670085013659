export default (theme) => ({
  card: {
    boxShadow: 'none',

    '& .MuiCardContent-root': {
      height: 101,
      minHeight: 'auto',
      padding: '14px 30px',
    },

    [theme.breakpoints.up(768)]: {
      '& .MuiCardContent-root': {
        height: 158,
        padding: '24px 79px',
      },
    },
  },
  cardHeader: {
    display: 'flex',
    gap: 32,
    flexDirection: 'row',
  },
  cardAvatar: {
    width: 73,
    height: 73,
    background: theme.palette.grey[400],
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white,
    flexDirection: 'column',

    [theme.breakpoints.up(768)]: {
      width: 110,
      height: 110,
    },
  },
  cardAvatarFallback: {
    background: 'transparent linear-gradient(180deg, #EF6F11 0%, #A67DF8 100%)',
  },
  cardAvatarImg: {
    width: 'inherit',
    height: 'inherit',
    borderRadius: '50%',
  },
  cardHeaderInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    gap: 4,

    [theme.breakpoints.up(768)]: {
      gap: 10,
    },
  },
  cardHeaderInfoDescription: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardTitleText: {
    fontSize: 12,
    fontWeight: 700,

    [theme.breakpoints.up(768)]: {
      fontSize: 16,
    },
  },
  cardSubtitleText: {
    fontSize: 12,
    fontWeight: 400,

    [theme.breakpoints.up(768)]: {
      fontSize: 16,
    },
  },
});
