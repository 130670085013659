import React, { useCallback } from 'react';
import {
  makeStyles,
  Typography,
  Box,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { ArrowRightOutlined as ArrowRightOutlinedIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';

import CardWithProgressLoading from '../CardWithLoadingProgress';
import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';
import { useRequest, useLazyRequest } from '../../hooks';
import {
  getCurrenciesNetworksConfig,
  generatePayment,
  deletePrevPayment,
} from '../../../services/merchantsService';
import { getHelperText, hasError } from '../../../utils';

import { FIELDS_NAME, initialValues, validationSchema } from './utils';
import styles from './styles';

const useStyles = makeStyles(styles);

const PaymentCouponForm = () => {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [currencies, loadingGetCurrencies] = useRequest(
    {
      initialState: [],
      request: getCurrenciesNetworksConfig,
      transformResponse: (response) => response.data.currencies,
    },
    [],
  );

  const [, loadingGeneratePayment, , requestGeneratePayment] = useLazyRequest({
    request: generatePayment,
    withPostSuccess: () => {
      enqueueSnackbar(t('paymentCoupon.generatePayment.successMessage'), {
        variant: 'success',
      });
    },
    withPostFailure: () => {
      enqueueSnackbar(t('paymentCoupon.generatePayment.errorMessage'), {
        variant: 'error',
      });
    },
  });

  const executeGeneratePayment = useCallback(
    async (values, { setSubmitting, resetForm }) => {
      await requestGeneratePayment({
        currency_id: values[FIELDS_NAME.PAYMENT_CURRENCY],
        amount: values[FIELDS_NAME.AMOUNT],
      });
      setSubmitting(false);
      resetForm();
    },
    [requestGeneratePayment],
  );

  const {
    touched,
    errors,
    submitForm,
    getFieldProps,
    dirty,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema: validationSchema(t),
    onSubmit: executeGeneratePayment,
  });

  const [
    ,
    loadingDeletePrevPayment,
    ,
    requestDeletePrevPayment,
  ] = useLazyRequest({
    request: deletePrevPayment,
    withPostSuccess: () => {
      enqueueSnackbar(t('paymentCoupon.deletePrePayment.successMessage'), {
        variant: 'success',
      });
    },
    withPostFailure: () => {
      enqueueSnackbar(t('paymentCoupon.deletePrePayment.errorMessage'), {
        variant: 'error',
      });
    },
  });

  const executeDeletePrevPayment = useCallback(async () => {
    await requestDeletePrevPayment();
  }, [requestDeletePrevPayment]);

  return (
    <CardWithProgressLoading
      loading={loadingGetCurrencies}
      className={classes.card}
    >
      <Box className={classes.cardContent}>
        <Typography
          color="inherit"
          variant="caption"
          className={classes.cardContentTitle}
        >
          {t('paymentCoupon.title')}
        </Typography>

        <Box className={classes.cardContentInputs}>
          <TextField
            fullWidth
            variant="outlined"
            label={t('paymentCoupon.paymentCurrency')}
            autoComplete="off"
            color="secondary"
            InputLabelProps={{ shrink: true }}
            helperText={getHelperText(
              touched,
              errors,
              FIELDS_NAME.PAYMENT_CURRENCY,
            )}
            error={hasError(touched, errors, FIELDS_NAME.PAYMENT_CURRENCY)}
            {...getFieldProps(FIELDS_NAME.PAYMENT_CURRENCY)}
            select
            SelectProps={{
              IconComponent: ArrowRightOutlinedIcon,
            }}
          >
            {currencies.map((currency) => (
              <MenuItem key={`currency-${currency.id}`} value={currency.id}>
                {currency.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            fullWidth
            variant="outlined"
            type="number"
            label={t('paymentCoupon.amount')}
            autoComplete="off"
            color="secondary"
            InputLabelProps={{ shrink: true }}
            helperText={getHelperText(touched, errors, FIELDS_NAME.AMOUNT)}
            error={hasError(touched, errors, FIELDS_NAME.AMOUNT)}
            {...getFieldProps(FIELDS_NAME.AMOUNT)}
          />
        </Box>
      </Box>

      <Box className={classes.cardActions}>
        <ButtonWithCirculaProgress
          variant="contained"
          color="secondary"
          fullWidth
          disabled={!dirty || !isValid || loadingDeletePrevPayment}
          loading={loadingGeneratePayment}
          label={t('paymentCoupon.generatePayment.title')}
          className={classes.cardActionsButton}
          handleButtonClick={submitForm}
        />

        <ButtonWithCirculaProgress
          variant="outlined"
          color="secondary"
          fullWidth
          disabled={loadingGeneratePayment}
          loading={loadingDeletePrevPayment}
          label={t('paymentCoupon.deletePrePayment.title')}
          className={classes.cardActionsButton}
          handleButtonClick={executeDeletePrevPayment}
        />
      </Box>
    </CardWithProgressLoading>
  );
};

export default PaymentCouponForm;
