export const PATHS = {
  home: { path: '/', title: 'Home' },
  login: { path: '/login', title: 'Login' },
  registration: { path: '/registration', title: 'Registro' },
  users: { path: '/users', title: 'users' },
  cashIn: { path: '/cash-in', title: 'cashIn' },
  cashOut: { path: '/cash-out', title: 'cashOut' },
  payments: { path: '/payments', title: 'payments' },
  wallet: { path: '/wallet', title: 'pos' },
  liquidations: { path: '/wallet/liquidations', title: 'liquidations' },
  liquidationRequest: {
    path: '/wallet/liquidation-request',
    title: 'requestToLiquidation',
  },
  movements: { path: '/wallet/movements', title: 'movementsHistory' },
  myStore: { path: '/my-store', title: 'store' },
  myStoreTokens: {
    path: '/my-store/tokens-currencies',
    title: 'currenciesTokens',
  },
  myStoreLocales: {
    path: '/my-store/locales',
    title: 'locals',
  },
  myStoreLocalNew: {
    path: '/my-store/locales/new',
    title: 'newLocal',
  },
  myStoreLocalEdit: {
    path: '/my-store/locales/:id/edit',
    title: 'editLocal',
  },
  settings: { path: '/settings', title: 'settings' },
  profile: { path: '/profile', title: 'profile' },
  aboutUs: { path: '/about-us', title: '¡Quiénes Somos!' },
  contact: { path: '/contact', title: 'Contacto' },
  termsAndConditions: { path: '/terms', title: 'termAndConditions' },
  privacyPolicy: { path: '/privacy', title: 'Políticas de privacidad' },
  frequentQuestions: {
    path: '/frequent-questions',
    title: 'Preguntas frecuentes',
  },
  confirmation: { path: '/confirm', title: 'Confirmación' },
  passwordRecovery: { path: '/recovery', title: 'Recuperar contraseña' },
  ChangePasswor: { path: '/change-password', title: 'Cambiar contraseña' },
  reports: { path: '/reports', title: 'reports' },
  reportsHistory: { path: '/reports/history', title: 'reportsHistory' },
  sharedAuth: { path: '/shared-auth', title: 'sharedAuth' },
};

export const URL_SANDBOX = [
  '/internal/v1/merchants/operations',
  '/internal/v1/merchants/integrations',
  '/internal/v1/merchants/integrations/request_to_enable',
];

export const BASE_URL = {
  staging: 'https://staging-app.kriptonmarket.com/',
  sandbox: 'https://sandbox.app.kriptonmarket.com',
  prod: 'https://app.kriptonmarket.com/',
};
