import { toAbsoluteUrl } from '../utils';

export const AVAILABLE_COUNTRIES = [
  {
    label: 'Argentina',
    labelShort: 'Arg',
    value: 'ARG',
    urlImg: toAbsoluteUrl('/assets/icons/flags/ARG.svg'),
    currency: 'ars',
  },
  {
    label: 'Colombia',
    labelShort: 'Col',
    value: 'COL',
    urlImg: toAbsoluteUrl('/assets/icons/flags/COL.svg'),
    currency: 'cop',
  },
  {
    label: 'Costa Rica',
    labelShort: 'C. Rica',
    value: 'CRI',
    urlImg: toAbsoluteUrl('/assets/icons/flags/CRI.svg'),
    currency: 'crc',
  },
  {
    label: 'El Salvador',
    labelShort: 'E. Sal',
    value: 'SLV',
    urlImg: toAbsoluteUrl('/assets/icons/flags/SLV.svg'),
    currency: 'usd',
  },
  {
    label: 'Uruguay',
    labelShort: 'Uru',
    value: 'URY',
    urlImg: toAbsoluteUrl('/assets/icons/flags/URY.png'),
    currency: 'uyu',
  },
  {
    label: 'Venezuela',
    labelShort: 'Ven',
    value: 'VEN',
    urlImg: toAbsoluteUrl('/assets/icons/flags/VEN.png'),
    currency: 'ves',
  },
];
