import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

export const DropdownContext = createContext({
  open: false,
  toggleOpen: () => {},
});

export const useDropdownContext = () => useContext(DropdownContext);

const DropdownProvider = ({ value, children }) => (
  <DropdownContext.Provider value={value}>{children}</DropdownContext.Provider>
);

DropdownProvider.propTypes = {
  value: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    toggleOpen: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default DropdownProvider;
