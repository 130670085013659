import { guttersApp } from '../../../utils/stylesUtils';

const contactLogoArea = 'footer-logo-area';
const contactRrssArea = 'footer-rrss-area';

export default (theme) => ({
  root: {
    alignItems: 'center',
    background:
      // eslint-disable-next-line max-len
      'linear-gradient(180deg, rgba(255, 204, 104, .8) 0%, rgba(240, 124, 28, .8) 38%, rgba(139, 84, 247, .8) 100%)',
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 30,
    paddingBottom: 32,
    width: '100%',

    [theme.breakpoints.up(768)]: {
      paddingTop: 41,
      paddingBottom: 30,
    },

    [theme.breakpoints.up(1024)]: {
      paddingTop: 20,
      paddingBottom: 30,
    },
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%',

    ...guttersApp(theme.spacing, theme.breakpoints),
  },
  newsletterContainer: {
    background: '#FFFFFF40',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '64px 32px 74px 32px',
    width: '100%',

    [theme.breakpoints.up(1024)]: {
      padding: '41x 69px 74px 69px',
    },

    [theme.breakpoints.up(1440)]: {
      padding: '64px 138px 74px 133px',
    },
  },
  newsletterTitle: {
    color: '#49454F',
    fontFamily: theme.typography.fontFamily,
    fontSize: 28,
    fontWeight: 700,
    lineHeight: '33.6px',
    margin: 0,
    marginBottom: 64,
    textAlign: 'center',

    [theme.breakpoints.up(768)]: {
      fontSize: 40,
      lineHeight: '48px',
    },
  },
  newsletterSubTitle: {
    color: '#484592',
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '19.2px',
    margin: 0,
    marginBottom: 32,
    textAlign: 'center',

    [theme.breakpoints.up(768)]: {
      fontSize: 20,
      marginBottom: 32,
    },
  },
  newsletterActions: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
  },
  newsletterActionsInput: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 16,
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '19.2px',
      backgroundColor: theme.palette.common.white,

      '& .MuiInputAdornment-root': {
        marginRight: 12,
        color: '#8B54F7',
      },

      '& .MuiOutlinedInput-input': {
        height: 72,
        padding: 0,
        paddingRight: 16,

        '&::placeholder': {
          color: '#8B54F7',
          opacity: 1,
        },
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#8B54F7',
      },
    },
  },
  contactContainer: {
    display: 'grid',
    gridGap: 0,
    gridTemplateAreas: `"${contactLogoArea}" "${contactRrssArea}"`,

    [theme.breakpoints.up(768)]: {
      gridTemplateAreas: `"${contactLogoArea} ${contactRrssArea}"`,
    },

    [theme.breakpoints.up(1024)]: {
      gridGap: 125,
    },

    [theme.breakpoints.up(1440)]: {
      gridGap: 16,
    },
  },
  contactMainLogo: {
    gridArea: contactLogoArea,
    height: 95,
    marginBottom: 28,
    width: 323,

    [theme.breakpoints.up(768)]: {
      margin: 0,
    },
  },
  contactContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  contactContentText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '19.2px',
    margin: 0,
    textAlign: 'left',
  },
  rrssContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    gridArea: contactRrssArea,
    justifyContent: 'space-between',
  },
  copyRight: {
    fontSize: 14,
    fontWeight: 400,
  },
  privacyContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  privacyLegal: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    textAlign: 'left',
    width: '100%',

    [theme.breakpoints.up(768)]: {
      borderBottom: '1px solid #F3F0F0',
      flexDirection: 'row',
      paddingBottom: 10,
    },
  },
  afipLink: {
    display: 'none',

    '& img': {
      height: 124,
      width: 91,
    },

    [theme.breakpoints.up(768)]: {
      display: 'block',
      marginRight: 82,
    },
  },
  privacyTypography: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontweight: 400,
    lineHeight: '22.4px',
    margin: 0,
    width: '100%',
  },
  privacyTerms: {
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
    gap: 16,

    [theme.breakpoints.up(768)]: {
      padding: '10px 0',
      gap: 30,
    },
  },
  privacyLink: {
    color: 'inherit',
    fontSize: 13,
    fontWeight: 600,
    lineHeight: '25.6px',
    textDecoration: 'underline',
  },
});
