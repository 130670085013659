import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, ClickAwayListener } from '@material-ui/core';
import cn from 'classnames';

import DropdownProvider from './Context';
import DropdownLink from './Link';
import DropdownCollapse from './Collapse';

import styles from './styles';

const useStyles = makeStyles(styles);

const Dropdown = ({ label, data, menuMinWidth = 200 }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleToggleOpen = useCallback(() => setOpen((prev) => !prev), []);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={classes.root}>
        <button
          type="button"
          onClick={handleToggleOpen}
          className={cn(classes.rootBtn, {
            [classes.rootOpen]: open,
          })}
        >
          <span>{label}</span>

          <span>▾</span>
        </button>

        <DropdownProvider value={{ open, toggleOpen: handleToggleOpen }}>
          {open && (
            <ul className={classes.menu} style={{ minWidth: menuMinWidth }}>
              {data.map(({ title, path, collapseData }) => (
                <li
                  key={`dropdown-menu-item-${title}`}
                  className={classes.menuItem}
                >
                  {path && <DropdownLink title={title} path={path} />}

                  {collapseData && (
                    <DropdownCollapse label={title} data={collapseData} />
                  )}
                </li>
              ))}
            </ul>
          )}
        </DropdownProvider>
      </div>
    </ClickAwayListener>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
      collapseData: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
        }).isRequired,
      ),
    }).isRequired,
  ).isRequired,
  menuMinWidth: PropTypes.number,
};

Dropdown.defaultProps = {
  menuMinWidth: 200,
};

export default Dropdown;
