const calculateMinHeightContainer = (heightHeader, isDesktop = false) => {
  const heigthWithHeader = isDesktop ? heightHeader : heightHeader + 40;
  return `calc(100vh - ${heigthWithHeader}px)`;
};

const coverDimensions = {
  1280: { width: 550, height: 503 },
  1024: { width: 392, height: 359 },
  768: { width: 544, height: 498 },
  480: { width: 360, height: 340 },
  375: { width: 286, height: 278 },
};

export default (theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    minHeight: ({ heightHeader }) =>
      calculateMinHeightContainer(heightHeader, true),
    overflow: 'hidden',
    paddingBottom: theme.spacing(7.5),
    paddingTop: theme.spacing(7.5),
    paddingLeft: 128,
    paddingRight: 128,
    maxWidth: 1440,
    position: 'relative',
    width: '100%',
    alignItems: 'center',
    backgroundImage:
      'linear-gradient(167deg, #819DEC00 20%, #819DEC 50%, #819DEC00 80%)',
    marginLeft: 'auto',
    marginRight: 'auto',

    [theme.breakpoints.down(1151)]: {
      paddingLeft: 95,
      paddingRight: 95,
      backgroundImage:
        'linear-gradient(170deg, #819DEC00 20%, #819DEC 50%, #819DEC00 80%)',
    },

    [theme.breakpoints.down(769)]: {
      paddingLeft: 30,
      paddingRight: 30,
      paddingBottom: theme.spacing(3.75),
      paddingTop: theme.spacing(3.75),
      backgroundImage:
        'linear-gradient(165deg, #819DEC00 10%, #819DEC 40%, #819DEC00 70%)',
      minHeight: ({ heightHeader }) =>
        calculateMinHeightContainer(heightHeader, false),
    },

    [theme.breakpoints.down(481)]: {
      paddingLeft: 15,
      paddingRight: 15,
      backgroundImage:
        'linear-gradient(180deg, #819DEC00 10%, #819DEC 40%, #819DEC00 60%)',
    },
  },
  container: {
    display: 'grid',
    gridGap: theme.spacing(4, 2),
    gridTemplateAreas: `
      "title cover"
      "info cover"
      "actions cover"
    `,
    gridTemplateColumns: `auto ${coverDimensions[1280].width}px`,
    width: '100%',

    [theme.breakpoints.down(1025)]: {
      gridTemplateAreas: `
        "title cover"
        "info cover"
        "actions actions"
      `,
      gridTemplateColumns: `auto ${coverDimensions[1024].width}px`,
    },

    [theme.breakpoints.down(769)]: {
      gridTemplateAreas: `
        "title"
        "cover"
        "info"
        "actions"
      `,
      gridTemplateColumns: 'auto',
      justifyItems: 'center',
    },
  },
  titleContainer: {
    gridArea: 'title',
    zIndex: 1,
    display: 'flex',
    alignItems: 'flex-end',
  },
  titleTypography: {
    fontSize: theme.typography.pxToRem(42),
    fontWeight: theme.typography.fontWeightBold,
    position: 'relative',
    textAlign: 'left',

    '& > span': {
      color: theme.palette.secondary.light,
    },

    [theme.breakpoints.down(1025)]: {
      fontSize: theme.typography.pxToRem(36),
    },

    [theme.breakpoints.down(769)]: {
      textAlign: 'center',
      maxWidth: 550,
    },

    [theme.breakpoints.down(481)]: {
      fontSize: theme.typography.pxToRem(28),
    },

    [theme.breakpoints.down(376)]: {
      fontSize: theme.typography.pxToRem(24),
    },
  },
  coverContainer: {
    gridArea: 'cover',
    width: '100%',
    height: '100%',
    maxHeight: coverDimensions[1280].height,
    zIndex: 0,

    [theme.breakpoints.down(1025)]: {
      maxHeight: coverDimensions[1024].height,
    },

    [theme.breakpoints.down(769)]: {
      maxWidth: coverDimensions[768].width,
      maxHeight: coverDimensions[768].height,
    },

    [theme.breakpoints.down(481)]: {
      maxWidth: coverDimensions[480].width,
      maxHeight: coverDimensions[480].height,
    },

    [theme.breakpoints.down(376)]: {
      maxWidth: coverDimensions[375].width,
      maxHeight: coverDimensions[375].height,
    },
  },
  coverSliderItemImg: {
    objectFit: 'contain',
    width: '100%',
  },
  infoContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    gridArea: 'info',
    position: 'relative',
    zIndex: 1,
    marginTop: 0,
  },
  infoTypography: {
    fontSize: theme.typography.pxToRem(21),
    textAlign: 'left',

    '& > span': {
      color: theme.palette.secondary.main,
    },

    [theme.breakpoints.down(481)]: {
      fontSize: theme.typography.pxToRem(17),
    },
  },
  actionsContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    gridArea: 'actions',
    position: 'relative',
    gap: theme.spacing(2),
    zIndex: 1,
    marginTop: 0,

    [theme.breakpoints.down(1025)]: {
      alignItems: 'center',
      marginTop: theme.spacing(6),
    },

    [theme.breakpoints.down(769)]: {
      marginTop: 0,
    },

    [theme.breakpoints.down(376)]: {
      width: '100%',
    },
  },
  actionsButton: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    maxWidth: 263,
    width: '100%',
    textTransform: 'none',

    [theme.breakpoints.down(1025)]: {
      maxWidth: 304,
    },

    [theme.breakpoints.down(769)]: {
      maxWidth: 336,
    },

    [theme.breakpoints.down(481)]: {
      maxWidth: 210,
    },

    [theme.breakpoints.down(376)]: {
      maxWidth: '100%',
    },
  },
  actionsTypography: {
    fontSize: theme.typography.pxToRem(16),
    textAlign: 'left',

    [theme.breakpoints.down(481)]: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
    },

    [theme.breakpoints.down(376)]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
});
