import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import cn from 'classnames';

import DropdownLink from './Link';

import styles from './styles';

const useStyles = makeStyles(styles);

const DropdownCollapse = ({ label, data }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleToggleOpen = useCallback(() => setOpen((prev) => !prev), []);

  const contentRef = useRef(null);
  const [heightContent, setHeightContent] = useState(0);

  useEffect(() => {
    if (open)
      setHeightContent(contentRef.current?.getBoundingClientRect().height);
    else setHeightContent(0);
  }, [open]);

  return (
    <div className={classes.collpaseRoot}>
      <button
        type="button"
        onClick={handleToggleOpen}
        className={cn(classes.collpaseBtn, {
          [classes.collapseOpen]: open,
        })}
      >
        <span>{label}</span>

        <span className={classes.collpaseBtnArrow}>▸</span>
      </button>

      <div
        className={cn(classes.collapseContent, {
          [classes.collapseOpen]: open,
        })}
        style={{ height: heightContent }}
      >
        <div ref={contentRef}>
          {data.map(({ title, path }) => (
            <DropdownLink
              key={`dropdown-menu-item-collapse-${title}`}
              title={title}
              path={path}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

DropdownCollapse.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default DropdownCollapse;
