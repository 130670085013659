import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';

const DialogConfirmation = ({
  open,
  title,
  description,
  warningText,
  onConfirm,
  onCancel,
  loadingConfirm,
}) => {
  const { t } = useTranslation('common');

  return (
    <Dialog
      open={open}
      aria-labelledby="dialog-confirmation-title"
      aria-describedby="dialog-confirmation-description"
      maxWidth="xs"
      fullWidth
      scroll="paper"
    >
      <DialogTitle id="dialog-confirmation-title">
        {title ?? t('general.areYouSure')}
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="dialog-confirmation-description">
          {description}
        </DialogContentText>

        {warningText && (
          <Alert variant="outlined" severity="warning">
            {warningText}
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button color="default" onClick={onCancel}>
          {t('general.cancel')}
        </Button>

        <ButtonWithCirculaProgress
          variant="contained"
          color="secondary"
          loading={loadingConfirm}
          label={t('general.confirm')}
          handleButtonClick={onConfirm}
        />
      </DialogActions>
    </Dialog>
  );
};

DialogConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  warningText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loadingConfirm: PropTypes.bool,
};

DialogConfirmation.defaultProps = {
  title: undefined,
  warningText: undefined,
  loadingConfirm: false,
};

export default DialogConfirmation;
