import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { makeStyles, Box, Typography } from '@material-ui/core';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import { useTranslation } from 'react-i18next';

import CardWithProgressLoading from '../CardWithLoadingProgress';

import { toAbsoluteUrl } from '../../../utils';

import styles from './styles';

const useStyles = makeStyles(styles);

const StorePresentation = ({
  loading,
  data: { logo, name, reference },
  state,
}) => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const isNotChecking = useMemo(() => state !== 'checking', [state]);

  const logoUrl = useMemo(() => {
    if (!logo) return undefined;

    return isNotChecking ? logo : toAbsoluteUrl('/assets/icons/ic_search.svg');
  }, [isNotChecking, logo]);

  return (
    <CardWithProgressLoading
      loading={loading}
      className={classes.card}
      progressSize={65}
      fullHeight={false}
    >
      <Box className={classes.cardHeader}>
        <Box
          className={cn(classes.cardAvatar, {
            [classes.cardAvatarFallback]: !logoUrl,
          })}
        >
          {logoUrl && (
            <Box
              component="img"
              src={logoUrl}
              alt="caritas"
              className={classes.cardAvatarImg}
            />
          )}

          {!logoUrl && (
            <>
              <CameraAltOutlinedIcon color="inherit" />

              <Typography color="inherit" variant="caption">
                {t(`store.yourLogo`)}
              </Typography>
            </>
          )}
        </Box>

        <Box className={classes.cardHeaderInfo}>
          <Typography className={classes.cardTitleText}>{name}</Typography>

          <Box className={classes.cardHeaderInfoDescription}>
            <Typography className={classes.cardSubtitleText}>
              {reference}
            </Typography>
          </Box>
        </Box>
      </Box>
    </CardWithProgressLoading>
  );
};

StorePresentation.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.shape({
    logo: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    reference: PropTypes.string,
  }),
  state: PropTypes.string,
};

StorePresentation.defaultProps = {
  loading: true,
  data: {
    logo: null,
    name: '',
    email: '',
    reference: '',
  },
  state: '',
};

export default StorePresentation;
