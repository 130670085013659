import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import MobileMenuLink from './Link';
import MobileMenuCollpase from './Collapse';

import styles from './styles';

const useStyles = makeStyles(styles);

const MobileMenuTabPanelContent = ({ label, data, handleClose }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const dataTranslated = useMemo(
    () =>
      data.map(({ title, path, collapseData }) => {
        let collapseProps;

        if (collapseData) {
          collapseProps = collapseData.map(
            ({ title: titleCollpase, path: pathCollpase }) => ({
              title: t(
                `headerDropdown.${label}.data.${title}.collapseData.${titleCollpase}.title`,
              ),
              path: pathCollpase,
            }),
          );
        }

        return {
          title: t(`headerDropdown.${label}.data.${title}.title`),
          path,
          collapseData: collapseProps,
        };
      }),
    [data, label, t],
  );

  return (
    <ul className={classes.tabPanelList}>
      {dataTranslated.map(({ title, path, collapseData }) => (
        <li
          key={`menu-mobile-tabs-panel-${title}-list`}
          className={classes.tabPanelListItem}
        >
          {path && (
            <MobileMenuLink
              title={title}
              path={path}
              handleClose={handleClose}
            />
          )}

          {collapseData && (
            <MobileMenuCollpase
              label={title}
              data={collapseData}
              handleClose={handleClose}
            />
          )}
        </li>
      ))}
    </ul>
  );
};

MobileMenuTabPanelContent.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
      collapseData: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
        }).isRequired,
      ),
    }).isRequired,
  ).isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default MobileMenuTabPanelContent;
