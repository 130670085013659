export default (theme) => ({
  root: {
    position: 'relative',
  },
  rootOpen: {},
  rootBtn: {
    backgroundColor: 'transparent',
    border: 0,
    color: '#49454F',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    gap: 6,
    padding: '0 10px',
    position: 'relative',
    zIndex: 1,

    '&:focus, &:focus-visible': {
      outline: 'none',
    },

    '&$rootOpen': {
      color: '#8B54F7',
    },
  },
  menu: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '0px 0px 4px 4px',
    borderTop: '2px solid #8B54F7',
    boxShadow: '0px 2px 6px 2px #00000026, 0px 1px 2px 0px #0000004D',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: theme.typography.fontFamily,
    fontSize: 15,
    fontWeight: 400,
    gap: 3,
    left: '50%',
    listStyle: 'none',
    margin: 0,
    padding: '8px 0',
    position: 'absolute',
    transform: 'translate(-50%, 10px)',
    zIndex: 1901,
  },
  menuItem: {
    display: 'flex',
  },
  collpaseRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  collapseOpen: {},
  collpaseBtn: {
    backgroundColor: 'transparent',
    border: 0,
    borderRadius: 16,
    color: theme.palette.common.black,
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    fontFamily: theme.typography.fontFamily,
    fontSize: 15,
    fontWeight: 400,
    gap: 6,
    justifyContent: 'space-between',
    padding: '8px 12px',

    '&:hover': {
      backgroundColor: '#1D1B2014',
      color: theme.palette.common.black,
    },

    '&:active': {
      backgroundColor: '#8B54F7',
      color: theme.palette.common.white,
    },

    '&$collapseOpen:not(:active) $collpaseBtnArrow': {
      color: '#8B54F7',
      transform: 'rotate(90deg)',
    },
  },
  collpaseBtnArrow: {
    transition: 'all .5s',
  },
  collapseContent: {
    display: 'flex',
    flexDirection: 'column',
    height: 0,
    marginTop: 0,
    overflow: 'hidden',
    paddingLeft: 24,
    transition: 'height .5s ease, margin .5s ease',
  },
});
