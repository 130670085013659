import React, { useCallback, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { PATHS } from '../../../constants/paths';
import { actions as authActions } from '../../modules/Auth/_redux/authRedux';
import { toAbsoluteUrl } from '../../../utils';
import { HEADER_MENU_ITEMS } from '../../../constants/headerMenuItems';

import MobileMenuTabPanelContent from './TabPanelContent';
import styles from './styles';

import 'react-tabs/style/react-tabs.css';

const useStyles = makeStyles(styles);

const MenuMobile = () => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [openMenu, setOpenMenu] = useState(false);
  const toggleOpen = useCallback(
    () => setOpenMenu((prevOpen) => !prevOpen),
    [],
  );

  useEffect(() => {
    setTimeout(() => {
      document.body.style.overflow = openMenu ? 'hidden' : 'auto';
    }, 300);
  }, [openMenu]);

  const [tabIndex, setTabIndex] = useState(0);

  const handleSelectTabIndex = useCallback((index) => {
    setTabIndex(index);
  }, []);

  const handleClose = useCallback(() => {
    toggleOpen();
    setTabIndex(0);
  }, [toggleOpen]);

  const redirectToLogin = useCallback(() => {
    history.push(PATHS.login.path);
    toggleOpen();
  }, [history, toggleOpen]);

  const redirectToRegister = useCallback(() => {
    history.push(PATHS.registration.path);
    toggleOpen();
  }, [history, toggleOpen]);

  const handleLogout = useCallback(() => {
    dispatch(authActions.logout());
    toggleOpen();
  }, [dispatch, toggleOpen]);

  return (
    <div className={classes.root}>
      <button type="button" className={classes.rootBtn} onClick={toggleOpen}>
        <div className={classes.rootBtnLines} />
      </button>

      {openMenu && (
        <nav className={classes.navBar}>
          <div className={classes.navBarHeader}>
            <img
              src={toAbsoluteUrl('/assets/images/logo2.svg')}
              alt="Logo"
              className={classes.mainLogo}
            />

            <button
              type="button"
              className={classes.closeBtn}
              onClick={handleClose}
            >
              &#10005;
            </button>
          </div>

          <div className={classes.navBarContent}>
            <Tabs
              selectedIndex={tabIndex}
              onSelect={handleSelectTabIndex}
              className={classes.tabs}
            >
              <TabList className={classes.tabList}>
                {HEADER_MENU_ITEMS.map(({ label }) => (
                  <Tab
                    key={`menu-mobile-tabs-${label}`}
                    className={classes.tab}
                    selectedClassName={classes.tabSelected}
                  >
                    {t(`headerDropdown.${label}.title`)}
                  </Tab>
                ))}
              </TabList>

              {HEADER_MENU_ITEMS.map(({ label, data }) => (
                <TabPanel
                  key={`menu-mobile-tabs-panels-${label}`}
                  className={classes.tabPanel}
                  selectedClassName={classes.tabPanelSelected}
                >
                  <MobileMenuTabPanelContent
                    label={label}
                    data={data}
                    handleClose={toggleOpen}
                  />
                </TabPanel>
              ))}
            </Tabs>

            <div className={classes.tabPanelSelected}>
              <ul className={classes.tabPanelList}>
                {!user && (
                  <>
                    <li className={classes.tabPanelListItem}>
                      <button
                        type="button"
                        className={classes.collpaseBtn}
                        onClick={redirectToLogin}
                      >
                        {t('header.login')}
                      </button>
                    </li>

                    <li className={classes.tabPanelListItem}>
                      <button
                        type="button"
                        className={classes.collpaseBtn}
                        onClick={redirectToRegister}
                      >
                        {t('header.createAccount')}
                      </button>
                    </li>
                  </>
                )}

                {user && (
                  <li className={classes.tabPanelListItem}>
                    <button
                      type="button"
                      className={classes.collpaseBtn}
                      onClick={handleLogout}
                    >
                      {t('header.logOut')}
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      )}
    </div>
  );
};

export default MenuMobile;
