import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box } from '@material-ui/core';
import cn from 'classnames';

import Header from '../Header';
import Footer from '../Footer';
import { useHeightHeaderFooter } from '../../hooks';

import styles from './styles';

const useStyles = makeStyles(styles);

const Layout = ({ children }) => {
  const classes = useStyles();
  const { classes: classesMinHeight } = useHeightHeaderFooter();

  return (
    <div>
      <Header />

      <Box
        component="main"
        className={cn(classes.root, classesMinHeight.minHeightWithHeader)}
      >
        {children}
      </Box>

      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
