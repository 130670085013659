import React, { useState } from 'react';
import { makeStyles, TextField, InputAdornment } from '@material-ui/core';
import { MailRounded as MailRoundedIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { toAbsoluteUrl } from '../../../utils';
import SocialNetworks from '../SocialNetworks';
import { PATHS } from '../../../constants/paths';

import styles from './styles';

const useStyles = makeStyles(styles);

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [inputVal, setInputVal] = useState('');

  return (
    <footer className={classes.root} id="footer-app">
      <div className={classes.toolbar}>
        <div className={classes.newsletterContainer}>
          <h6 className={classes.newsletterSubTitle}>
            {t('footer.newsLetter.title')}
          </h6>

          <div className={classes.newsletterActions}>
            <TextField
              value={inputVal}
              onChange={(event) => setInputVal(event.target.value)}
              fullWidth
              variant="outlined"
              autoComplete="off"
              placeholder={t('footer.newsLetter.input.placeholder', {
                defaultValue: 'Tu Email',
              })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailRoundedIcon />
                  </InputAdornment>
                ),
              }}
              className={classes.newsletterActionsInput}
            />
          </div>
        </div>

        <div className={classes.contactContainer}>
          <img
            src={toAbsoluteUrl('/assets/images/logo_horizontal.svg')}
            alt="kriptonmarket"
            className={classes.contactMainLogo}
          />

          <div className={classes.rrssContainer}>
            <div className={classes.contactContent}>
              <p className={classes.contactContentText}>
                {t('footer.contact.title')}
              </p>
            </div>

            <SocialNetworks />

            <span className={classes.copyRight}>
              {t('footer.contact.copyRight')}
            </span>
          </div>
        </div>

        <div className={classes.privacyContainer}>
          <div className={classes.privacyLegal}>
            <a
              href="http://qr.afip.gob.ar/?qr=sA8U0zUxa70OsmYHj0ihYQ,,"
              target="_F960AFIPInfo"
              className={classes.afipLink}
            >
              <img
                src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg"
                alt="Afip img"
              />
            </a>

            <p className={classes.privacyTypography}>
              {t('footer.privacy.aclaration')}
            </p>
          </div>

          <div className={classes.privacyTerms}>
            <RouterLink
              to={PATHS.privacyPolicy.path}
              className={classes.privacyLink}
            >
              {t(`footer.privacy.privacy`)}
            </RouterLink>

            <RouterLink
              to={PATHS.termsAndConditions.path}
              className={classes.privacyLink}
            >
              {t(`footer.privacy.terms`)}
            </RouterLink>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
