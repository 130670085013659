import React from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, ThemeProvider, CssBaseline } from '@material-ui/core';

import { MontserratRegular, HelveticaNeue, Roboto } from './fonts';

export const fontFamily = ['Montserrat', 'Helvetica Neue', 'Roboto'].join(',');

const theme = createMuiTheme({
  typography: { fontFamily },
  palette: {
    primary: {
      main: '#F39224',
    },
    secondary: {
      main: '#8B54F7',
    },
    background: {
      default: '#FCF9F9',
      gradient: {
        primary: 'linear-gradient(180deg, #F89737 0%, #EF6F11 100%)',
        mix: 'linear-gradient(180deg, #A67DF8 0%, #EF6F11 100%)',
        mix2: 'transparent linear-gradient(270deg, #6E49AE 0%, #F89737 100%)',
      },
    },
  },
  breakpoints: {
    values: {
      xl: 1440,
      lg: 1024,
      md: 768,
      sm: 480,
      xs: 0,
    },
  },
  props: {
    MuiButton: {
      variant: 'contained',
      color: 'primary',
      disableElevation: true,
    },
    MuiCard: {
      elevation: 0,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [MontserratRegular, HelveticaNeue, Roboto],
      },
    },
    MuiButton: {
      root: {
        borderRadius: 41,
      },
    },
    MuiCard: {
      root: {
        overflow: 'inherit',
        borderRadius: 24,
        boxShadow: '0px 5px 6px #00000029',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 24,
        boxShadow: '0px 5px 6px #00000029',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 24,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'transparent',
        backdropFilter: 'blur(13px)',
        WebkitBackdropFilter: 'blur(13px)',
      },
    },
  },
});

export const MaterialThemeProvider = ({ children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);

MaterialThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
