import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Link as LinkMaterial } from '@material-ui/core';

import LinkRouter from '../LinkRouter';

import styles from './styles';

const useStyles = makeStyles(styles);

const MobileMenuLink = ({ title, path, handleClose }) => {
  const classes = useStyles();

  const isExternalPath = useMemo(() => path.match(/^http|^https|^www/), [path]);

  if (isExternalPath) {
    return (
      <LinkMaterial
        href={path}
        underline="none"
        color="inherit"
        className={classes.collpaseBtn}
        onClick={handleClose}
      >
        {title}
      </LinkMaterial>
    );
  }

  return (
    <LinkRouter
      to={path}
      color="inherit"
      underline="none"
      className={classes.collpaseBtn}
      onClick={handleClose}
    >
      {title}
    </LinkRouter>
  );
};

MobileMenuLink.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default MobileMenuLink;
