export default (theme) => ({
  socialNetworks: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: 16,

    [theme.breakpoints.up(768)]: {
      gap: 30,
    },
  },
  rrssLink: {
    display: 'inline-flex',

    '& img': {
      height: 32,
      width: 32,
    },
  },
  afipLink: {
    display: 'block',

    '& img': {
      height: 65,
      width: 48,
    },

    [theme.breakpoints.up(768)]: {
      display: 'none',
    },
  },
});
