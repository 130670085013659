import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Typography, makeStyles, ClickAwayListener } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { AVAILABLE_COUNTRIES } from '../../../constants/availablesCountries';
import { actions as authActions } from '../../modules/Auth/_redux/authRedux';

import styles from './styles';

const useStyles = makeStyles(styles);

const CountryDropdown = ({ positionLeftMobile }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { currentCountry } = useSelector((state) => state.auth);

  const [openMenu, setOpenMenu] = useState(false);
  const handleToggleMenu = useCallback(() => setOpenMenu((prev) => !prev), []);

  const handleChangeCountry = useCallback(
    (country) => {
      dispatch(authActions.setCurrentCountry(country));
      handleToggleMenu();
    },
    [dispatch, handleToggleMenu],
  );

  const flagInfo = useMemo(
    () =>
      AVAILABLE_COUNTRIES.find((country) => country.value === currentCountry),
    [currentCountry],
  );

  return (
    <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
      <div className={classes.root}>
        <button
          type="button"
          className={classes.rootBtn}
          onClick={handleToggleMenu}
        >
          <img src={flagInfo?.urlImg} alt={flagInfo?.label} />
        </button>

        {openMenu && (
          <nav
            className={classnames(classes.menu, {
              [classes.menuLeftMobile]: positionLeftMobile,
            })}
          >
            <div className={classes.menuItems}>
              {AVAILABLE_COUNTRIES.map(
                ({ label, labelShort, value: country, urlImg }) => (
                  <div
                    className={classes.menuElementContent}
                    key={`country-menu-element-${country}`}
                  >
                    <button
                      type="button"
                      className={classes.menuElementBtn}
                      onClick={() => handleChangeCountry(country)}
                    >
                      <img src={urlImg} alt={label} />

                      <Typography
                        className={classes.menuElementText}
                        color="inherit"
                      >
                        {labelShort}
                      </Typography>
                    </button>
                  </div>
                ),
              )}
            </div>
          </nav>
        )}
      </div>
    </ClickAwayListener>
  );
};

CountryDropdown.propTypes = {
  positionLeftMobile: PropTypes.bool,
};

CountryDropdown.defaultProps = {
  positionLeftMobile: false,
};

export default CountryDropdown;
