export default (theme) => ({
  card: {
    boxShadow: 'none',

    '& .MuiCardContent-root': {
      height: 464,
      padding: '34px 20px',
      gap: 20,

      '&:last-child': {
        paddingBottom: 34,
      },
    },

    [theme.breakpoints.up(768)]: {
      '& .MuiCardContent-root': {
        height: 382,
        padding: '34px 79px',

        '&:last-child': {
          paddingBottom: 34,
        },
      },
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
  },
  cardContentTitle: {
    color: '#49454F',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '27px',
    letterSpacing: '0.5px',

    [theme.breakpoints.up(768)]: {
      fontSize: 18,
    },
  },
  cardContentInputs: {
    display: 'flex',
    gap: 10,
    flexDirection: 'column',

    '& .MuiInputLabel-outlined': {
      color: '#8B54F7',
    },

    '& .MuiSelect-icon': {
      fontSize: 40,
      top: 'calc(50% - 20px)',
      color: '#8B54F7',

      '&.MuiSelect-iconOpen': {
        transform: 'rotate(90deg)',
      },
    },

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#8B54F7',
      },
    },
  },
  cardActions: {
    display: 'flex',
    gap: 15,
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.up(768)]: {
      gap: 40,
      flexDirection: 'row-reverse',
      justifyContent: 'center',
    },
  },
  cardActionsButton: {
    maxWidth: 286,

    '& .MuiButton-root': {
      padding: '19.5px 16px',
      borderRadius: 16,
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 700,
      textTransform: 'none',
    },
  },
});
