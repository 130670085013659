import { object, string } from 'yup';

export const FIELDS_NAME = {
  PAYMENT_CURRENCY: 'paymentCurrency',
  AMOUNT: 'amount',
};

export const initialValues = {
  [FIELDS_NAME.PAYMENT_CURRENCY]: '',
  [FIELDS_NAME.AMOUNT]: '',
};

export const validationSchema = (t) =>
  object().shape({
    [FIELDS_NAME.PAYMENT_CURRENCY]: string().required(
      t('validations.required'),
    ),
    [FIELDS_NAME.AMOUNT]: string().required(t('validations.required')),
  });
