import * as Yup from 'yup';

export const FIELDS_NAME = {
  EMAIL: 'email',
  ROLE: 'role',
};

export const initialValues = {
  [FIELDS_NAME.EMAIL]: '',
  [FIELDS_NAME.ROLE]: '',
};

export const validationSchema = (t) =>
  Yup.object().shape({
    [FIELDS_NAME.EMAIL]: Yup.string()
      .email(t('login.invalidMail'))
      .required(t('validations.required')),
    [FIELDS_NAME.ROLE]: Yup.string().required(t('validations.required')),
  });
