/* eslint-disable react/forbid-prop-types */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Card, Button } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import { merge } from 'lodash';
import cn from 'classnames';

import CircularProgress from '../CircularProgress';
import NoDataItem from '../NoDataItem';

import styles from './styles';
import defaultStylesDataTable, {
  minHeightPagination,
} from './defaultStylesDataTableComponent';

const useStyles = makeStyles(styles);

const paginationRowsPerPageOptions = [5, 10, 15, 20, 25, 30];

const TableList = ({
  data,
  columns,
  perPage,
  totalRows,
  loading,
  handlePerRowsChange,
  handlePageChange,
  customStyles,
  noRowsPerPage,
  classNameContainerCard,
  pagination,
  extraAction,
  labelExtraAction,
  handleExtraAction,
  ...rest
}) => {
  const classes = useStyles({
    minHeightPagination,
  });

  const stylesDataTable = useMemo(
    () => merge(defaultStylesDataTable, customStyles),
    [customStyles],
  );

  return (
    <Card className={cn(classes.cardContainer, classNameContainerCard)}>
      <DataTable
        fixedHeader
        fixedHeaderScrollHeight="425px"
        persistTableHead
        noHeader
        columns={columns}
        data={data}
        paginationPerPage={perPage}
        progressPending={loading}
        progressComponent={<CircularProgress />}
        pagination={pagination}
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationComponentOptions={{
          rowsPerPageText: 'Filas por página',
          rangeSeparatorText: 'de',
          noRowsPerPage,
        }}
        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
        noDataComponent={<NoDataItem />}
        highlightOnHover
        className={cn(classes.tableContainer, 'table-list-container')}
        customStyles={stylesDataTable}
        {...rest}
      />

      {!loading && extraAction && (
        <Button
          color="secondary"
          onClick={handleExtraAction}
          className={classes.extraButton}
        >
          {labelExtraAction}
        </Button>
      )}
    </Card>
  );
};

TableList.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  perPage: PropTypes.number,
  totalRows: PropTypes.number,
  loading: PropTypes.bool,
  handlePerRowsChange: PropTypes.func,
  handlePageChange: PropTypes.func,
  customStyles: PropTypes.object,
  noRowsPerPage: PropTypes.bool,
  classNameContainerCard: PropTypes.string,
  pagination: PropTypes.bool,
  extraAction: PropTypes.bool,
  labelExtraAction: PropTypes.string,
  handleExtraAction: PropTypes.func,
};

const voidFunction = () => null;

TableList.defaultProps = {
  data: [],
  columns: [],
  perPage: 25,
  totalRows: 0,
  loading: false,
  handlePerRowsChange: voidFunction,
  handlePageChange: voidFunction,
  customStyles: {},
  noRowsPerPage: false,
  classNameContainerCard: null,
  pagination: true,
  extraAction: false,
  labelExtraAction: 'Ver mas',
  handleExtraAction: voidFunction,
};

export default TableList;
