import { useState, useCallback } from 'react';

import { useToggle } from './useToggle';

export const useToggleDialogConfirmation = (initialData, initialOpen) => {
  const [openDialog, toggleOpenDialog] = useToggle(initialOpen);

  const [data, setData] = useState(initialData);

  const handleOpen = useCallback(
    (newData, delay) => {
      setData(newData);

      setTimeout(() => {
        toggleOpenDialog(true);
      }, delay);
    },
    [toggleOpenDialog],
  );

  const handleClose = useCallback(
    (delay = 500) => {
      toggleOpenDialog(false);

      setTimeout(() => {
        setData(initialData);
      }, delay);
    },
    [initialData, toggleOpenDialog],
  );

  return [openDialog, data, handleOpen, handleClose];
};
